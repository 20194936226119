<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title class="mb-1"> Top-Behandlung </b-card-title>
        <b-card-text class="font-small-2"> Nach Umsatzerlösen </b-card-text>
      </div>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', defaultDate: defaultRangePicker }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
        <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item @click="onExport()"> {{ $t("export") }} </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(browser) in revenueData"
        :key="browser.browserImg"
        class="browser-states"
      >
        <b-media vertical-align="center">
          {{ browser.service_name }}
          <small class="text-muted">{{ browser.category }}</small>
        </b-media>
        
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1"
            >CHF {{ browser.total }}</span
          >
          <!-- <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          /> -->
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BLink,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import reportStoreModule from "./reportStoreModule";
import _ from "lodash";
import axios from "@axios"

/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BLink,
    VueApexCharts,
    flatPickr,
  },
  props: {
    defaultRangePicker: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rangePicker: "",
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      revenueData: [],
      browserData: [
        {
          //browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: "Westbrooke Pointin",
          usage: "32434",
        },
        {
          //browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: "Koral Staniforth",
          usage: "43433",
        },
        {
          //browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: "Alfreda Lamba",
          usage: "3343",
        },
        {
          //browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: "Eadie Colquyte",
          usage: "2666",
        },
        {
          //browserImg: require('@/assets/images/icons/opera.png'),
          name: "Sibeal Bonds",
          usage: "1334",
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = "app-report";

    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME))
      store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule);

    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });
  },
  watch: {
    rangePicker: function (newQuestion, oldQuestion) {
      this.debouncedFetchRevenueData();
    },
  },
  created: function () {
    // for (let i = 0; i < this.browserData.length; i += 1) {
    //   const chartClone = JSON.parse(JSON.stringify(this.chart));
    //   chartClone.options.colors[0] = this.chartColor[i];
    //   chartClone.series[0] = this.chartSeries[i];
    //   this.chartData.push(chartClone);
    // }
    
    this.debouncedFetchRevenueData = _.debounce(this.fetchRevenueData, 1000);
  },
  async mounted() {
    this.debouncedFetchRevenueData();
  },
  methods: {
    onExport() {
      var vm = this;
      var from = "";
      var to = "";
      var selectedRange = this.rangePicker && this.rangePicker.split("to");
      if (selectedRange.length == 1) return;
      if (selectedRange.length == 2) {
        from = selectedRange[0].trim();
        to = selectedRange[1].trim();
      }

      axios({
        url: `/behandlung/export-services-by-revenues`,
        method: "GET",
        responseType: "blob",
        params: {
          from: from,
          to: to,
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename] =
          response.headers["content-disposition"].split("filename=");
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetchRevenueData: function () {
      var from = "";
      var to = "";
      var selectedRange = this.rangePicker && this.rangePicker.split("to");
      if (selectedRange.length == 1) return;
      if (selectedRange.length == 2) {
        from = selectedRange[0].trim();
        to = selectedRange[1].trim();
      }

      this.loaded = false;
      store
        .dispatch("app-report/fetchTopServicesByRevenues", {
          from: from,
          to: to,
          top: 5
        })
        .then((response) => {
          let revenueData = response.data.data;
          this.revenueData = revenueData.data;
          // for (let i = 0; i < revenueData.data.length; i += 1) {
          //   //const chartClone = JSON.parse(JSON.stringify(this.chart));
          //   //chartClone.options.colors[0] = this.chartColor[i];
          //   //chartClone.series[0] = this.chartSeries[i];
          //   this.revenueData.push(chartClone);
          // }

          this.loaded = true;
        })
        .catch((error) => {
          if (error && error.response.status === 404) {
            //this.chartData = null;
          }
        });
    },
  },
};
</script>
