<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-card-header>
        <div>
          <b-card-title class="mb-1"> Revenue Services </b-card-title>
        </div>
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon icon="CalendarIcon" size="16" />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range' }"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        <!-- datepicker -->
      </b-card-header>

      <b-table
        ref="refTreatmentListTable"
        class="position-relative"
        :items="fetchTreatments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id } }"
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t("Details") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTreatments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";

import useServicesList from "./useServicesList";
import treatmentStoreModule from "../treatment/treatmentStoreModule";

import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    flatPickr,
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = "app-treatment";

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_STORE_MODULE_NAME, treatmentStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const {
      fetchTreatments,
      tableColumns,
      perPage,
      currentPage,
      totalTreatments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTreatmentListTable,
      refetchData,
    } = useServicesList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchTreatments,
      tableColumns,
      perPage,
      currentPage,
      totalTreatments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTreatmentListTable,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
